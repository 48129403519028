<template>
  <v-layout
    column
    justify-start
    :class="!bMini ? 'viewLayoutWrapper MiniPadding' : 'viewLayoutWrapper'"
  >
    <!-- Start title -->
    <div class="SectionTitle d-flex align-center">
      <v-btn icon fab small @click="goBack()">
        <v-icon style="font-size: 30px; color: black">
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <span> Detalle de orden de compra </span
      ><span
        ><v-chip
          class="ml-2"
          text-color="#FFFFFF"
          :color="getColorStatusOC()"
          >{{ statusName }}</v-chip
        ></span
      >
    </div>
    <purchase-order :bReadonlyMaterials="true" @setStatusOC="setStatusOC" />
  </v-layout>
</template>

<script>
import PurchaseOrder from "@/components/core/PurchaseOrder";

export default {
  name: "DetailsProjects",
  props: {
    texts: Object,
    mdUp: Boolean,
    smUp: Boolean,
    bMini: Boolean,
  },
  data() {
    return {
      nameProject: "",
      statusName: "",
    };
  },
  beforeMount() {
    //   this.getProjectDetail();
  },
  methods: {
    // get detail proyect
    getProjectDetail() {
      db.get(`${uri}/api/v1/projects/${this.$route.params.sProyectId}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.nameProject = resp.data.project.sName;
          this.statusName = resp.data.project.ProjectStatusRecords.sName;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    goBack() {
      this.$router.push({
        name: "OCs",
      });
    },
    setStatusOC(oStatusOrder) {
      this.statusName = oStatusOrder.statusOrder;
    },
    getColorStatusOC() {
      switch (this.statusName) {
        case "Enviada":
          return "#79c6ff";

        case "Borrador":
          return "#7e7e7e";

        case "Aprobada":
          return "#97e6b6";
        case "Recibida":
          return "#97e6b6";

        case "Correcciones":
          return "#ff7d7d";

        case "Pagada":
          return "#ffce00";

        case "Cancelada":
          return "#ff7d7d";

        default:
          break;
      }
    },
  },
  computed: {},
  watch: {},
  components: {
    PurchaseOrder,
  },
};
</script>

<style>
.v-progress-linear__content {
  align-items: center;
  display: flex;
  height: 100%;
  left: 0;
  justify-content: center !important;
  margin-left: 5px !important;
  position: absolute;
  top: 0;
  width: 100%;
}
.btn-sincategorizar[disabled] {
  color: transparent;
}
.footer-table {
  background-color: #fff;
  height: 56px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.footer-table p {
  margin-bottom: 0;
  color: #696969;
  font-size: 12px;
  letter-spacing: 0;
}

.footer-table-buttons {
  background-color: #fff !important;
}

.footer-table-buttons i {
  font-size: 24px;
  opacity: 0.54;
  color: #475f7b !important;
}

.footer-table-dropdown {
  opacity: 0.54;
  color: #475f7b;
  font-size: 12px;
  letter-spacing: 0;
}

.v-tabs-slider-wrapper {
  height: 6px !important;
  border-radius: 10px !important;
  border: solid 1px white;
  border-bottom-color: #ffce00;
  width: auto;
}

.v-slide-group__wrapper {
  border: solid 1px white;
  border-bottom-color: #c4c4c4;
  width: auto;
}
</style>
<style scoped>
.text-progressbar {
  margin-bottom: 0;
  font-family: "Poppins", sans-serif;
  color: #2e2e2e;
  font-weight: 600;
  font-size: 12px;
}

.texto-barra-porcentual {
  font-family: "Poppins", sans-serif;
  color: #2e2e2e;
  font-weight: 600;
}

.global-tab {
  font-family: "Poppins", sans-serif !important;
  color: black;
  /* letter-spacing: inherit !important; */
  text-transform: none !important;
  width: 250px !important;
  font-size: 15px !important;
  letter-spacing: -0px !important;
  font-weight: bolder;
}
</style>
